import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom"



const Modal = ({show=false, setShow, children, onClose=()=>{}})=>{
    const [state, setState] = useState(show?"open-": "close-");

    const close = useCallback(()=>{
        setShow(false);
    }, [setShow])

   
    useLayoutEffect(()=>{
        if(show){
            setState("open-");
        }
        else{
            setState(s=>{
                if(s === "close-"){
                    return s
                }
                setTimeout(()=>{
                    setState("close-");
                }, 1000)
                return "loading-"
                
            })
        }

    }, [show])

    useEffect(()=>{
        state === "close-" && onClose();
    }, [state, onClose])


    return createPortal(
        <div className={`modal ${(state || "close-")+"modal"}`}>
            
            <div className="container">
                
                <div style={{zIndex: 233}}>

                    <span onClick={()=>close()}>&times;</span>

                    <div className="children">
                        {children}
                    </div>
                </div>
                
            </div>
        </div>,
        document.body
    )
}

export default Modal;