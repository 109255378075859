
let timeouts = {}

export function textWritter({elm, contentText, duration, timeout=0, key="",callback=null, before=()=>{}}){
    if (!elm) return

    const text = contentText;
    elm.innerHTML = "";
    elm.innerText = '';
    
    elm.style.opacity = 1;

    const d = duration/text.length;

    key += "textWritter";
    
    clearTimeout(timeouts[key])
    timeouts[key] = setTimeout(()=>{
        before();
        for (let i = 0; i < text.length; i++) {
            
            let lb = document.createElement('span');
            
            lb.innerText = text[i];
            
            lb.style.animation = `opacify ${d}s ease-in-out ${d*i}s both`
            elm.append(lb)
            
        }

        clearTimeout(timeouts[key+2])
        timeouts[key+2] = setTimeout(()=>{
            elm.innerText = text
        
            callback && callback()
        }, duration * 1000)

    }, timeout*1000)
        
}

export function textClear({elm, duration, timeout=0, key="", callback=()=>{}}){

    key += "textClear";
    const text = elm.innerText;
    
    clearTimeout(timeouts[key]);
    timeouts[key] = setTimeout(()=>{
        const d = duration / text.length;
        elm.innerText = "";
        for (let i = 0; i < text.length; i++) {
            let lb = document.createElement("label");
            lb.innerText = text[i]
            lb.style.animation = `opacify ${d}s ease-in-out ${d*(text.length - i - 1)}s both reverse`;
            elm.append(lb);
        }

        clearTimeout(timeouts[key+2]);

        timeouts[key+2] = setTimeout(()=>{
            callback();
        }, duration*1000)
        
    }, timeout * 1000)
    
}

export function setAnimation({elm, name, duration, timingFunction="", delay=0, iterationCount=1, direction="", fillMode="both", key, callback, before}){
    
    if (!elm) return  
    
    key && clearTimeout(timeouts[key+"before"])

    
    let tb = setTimeout(()=>{
        before && before()
    }, delay * 1000)

    timeouts[key+"before"] = tb

    elm.style.opacity = 1 
    elm.style.animation = ""

    elm.style.animation = `${name}  ${duration}s ${delay}s ${fillMode} ${direction} ${iterationCount} ${timingFunction}`

    key && clearTimeout(timeouts[key])
    let t = setTimeout(()=>{
        elm.style.animation = ""
        callback && callback()
    }, (duration + delay) * 1000 )

    timeouts[key] = t
}

