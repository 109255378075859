import axios from "axios";
import { useCallback, useState } from "react";
import Modal from "./Modal";

const HOST = 
"https://api.projetsmart.com/";
// "http://127.0.0.1:8000/";



const Started = ({show=false, setShow})=>{
    const [res, setRes] = useState({show: false});

    const send = useCallback(e=>{
        e.preventDefault();
        const f = e.target;

        const data = {
            "full_name": `${f.first_name.value} ${f.last_name.value}`,
            "email": f.email.value,
            "phone_number": f.phone_number.value,
            "message": f.message.value,
        }
      
        axios.post(
            `${HOST}messages/`,
            data,
            {
                headers:{ 
                    "Content-Type": 'application/json'
                }
            }
        ).then(()=>{
            
            setShow(false);
            setRes({show: true, type: "success"});
        }).catch(()=>{
            setShow(false);
            setRes({show: true, type: "error"});
        })
    }, [setShow])

    return( 
        <>
            <Modal {...{show, setShow}}>

                {/* <div className="form"> */}
                    <form onSubmit={send}>
                        <div className="name">
                            <div  className="form-group">
                                <label htmlFor="last_name">Prénom</label>
                                <input type="text" id="last_name" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="first_name">Nom</label>
                                <input type="text" id="first_name" className="form-control" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone_number">Télephone</label>
                            <input type="text" placeholder="+229 00 00 00 00" id="phone_number" className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="message" rows="2" placeholder="Décrivez-nous vos besoins" className="form-control" required></textarea>
                        </div>
                        <div className="form-group" style={{textAlign: "right"}}>
                            <button className="btn">Envoyer</button>

                        </div>
                        <div style={{textAlign: "center"}}>
                            <a href="mailto:smartentreprisebenin@gmail.com">smartentreprisebenin@gmail.com</a>
                        </div>
                        

                    </form>
                {/* </div> */}
            </Modal>
            <Modal show={res.show} setShow={v=>setRes(r=>({...r, show: v}))}>
                {res.type === "success"?
                    <div className="res-success">
                        Votre demande a été soumise avec succès. Nous vous contacterons dans les plus brefs délais après analyse.
                    </div>
                :
                    <div className="res-error">
                        La soumission de votre demande a échoué. Merci de réessayer ou de nous contacter directement par 
                        <> </><a href="mailto:smartentreprisebenin@gmail.com"><i>e-mail</i></a>.
                        <br/>
                        <strong>smartentreprisebenin@gmail.com</strong>
                    </div>
                }
            </Modal>
        </>
    )
}

export default Started;