import { useCallback, useEffect, useState } from "react";
import {motion, useAnimation} from "framer-motion";
import { useParams } from "react-router-dom";
import axios from "axios";

const host = 
// "http://127.0.0.1:8000/"
//"http://192.168.1.115:8000/"
"https://api.projetsmart.com/"

const InvCaisa = ()=>{
    const {pk} = useParams();
    const [inv, setInv] = useState(null);
    const [err, setError] = useState(null);
    const [acc, setAcc] = useState("");
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const errorManager = useCallback((err)=>{
        let s = err.response.status;
            if(s===404){
                setError("Veuillez vérifier le lien de l'invitation");
            }
            else {
                setError("Veuillez vérifier votre connexion internet et réessayer");
            }
            setLoading(false)
    }, [])

    useEffect(()=>{
    	document.title = "Invitation Caisa"
    }, [])

    useEffect(()=>{
        if(!pk) return
        setLoading(true)
        axios.get(
            `${host}invitations/${pk}/`
        )
        .then(res=>{
            setInv(res.data)
            setLoading(false)
        })
        .catch(errorManager)
    }, [pk, errorManager])

    useEffect(()=>{
        if(!inv) return;
        setAcc(inv.accompanied || "")
        setDone(inv.accepted);
    }, [inv])

    const confirm = useCallback(()=>{
        setLoading(true);
        axios.post(
            `${host}invitations/confirm/${pk}/`,
            {
                accompanied: acc
            }
        ).then(()=>{
            setLoading(false);
            setDone(true);
        }).catch(errorManager)
    }, [acc, pk, errorManager])

    return (
        <div className="inv-caisa">
            <div className="containt">
                <motion.header
                    initial={{y: -100, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{duration: 2}}
                >
                    <h3>
                        {!inv? 
                            "Bienvenue"
                            : `${inv.gender==="M"? "Mr": "Mme"} ${inv.full_name}`
                        }
                    </h3>
                    {inv && inv.accompanied && (
                        <small> & {inv.accompanied}</small>
                    )}
                    <small>{}</small>
                </motion.header>
                
                <motion.div className="body"
                    layout
                    animate={{
                        clipPath: "inset(0% 0% 0% 0% round 5px)",
                        transition: {
                            type: "spring",
                            bounce: 0,
                            duration: 0.7,
                            
                        }
                    }}
                    initial={{
                        clipPath: "inset(10% 50% 90% 50% round 5px)",
                        transition: {
                            type: "spring",
                            bounce: 0,
                            duration: 0.3
                        }
                    }}
                >
                    {inv && !done && (
                        <div className="inv">
                            <h4>Souhaiteriez-vous être accompagné à l'invitation ?</h4>
                            <div>
                                <motion.input
                                    type="text" id="accompanied" placeholder="Entrer Nom et Prénom de votre invité"
                                    whileTap={{
                                        scale: 1.02
                                    }}
                                    whileFocus={{
                                        boxShadow: "0 0 3px 1px rgba(0, 0, 0, .5)"

                                    }}
                                    value={acc}
                                    onChange={e=>setAcc(e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    { inv && (
                        <div className="inv">
                            <div className="confirm">
                                {!done? (
                                    <>
                                        <h4>Merci de comfirmer votre présence!</h4>
                                        <motion.div
                                            className="button"
                                            whileHover={{boxShadow: "0 0 2px 1px rgba(0, 0, 0, .2)"}}
                                            whileTap={{scale: .97}}
                                            onClick={confirm}
                                        >
                                            Confirmer
                                        </motion.div>
                                    </>

                                ):(
                                    <div>
                                        <h4>Votre présence à été confirmée avec succès</h4>
                                        <div style={{textAlign: "center", margin: "2rem 0"}}>

                                            <AnimatedCheckIcon/>
                                        </div>

                                        <motion.a
                                            className="button"
                                            whileHover={{boxShadow: "0 0 2px 1px rgba(0, 0, 0, .2)"}}
                                            whileTap={{scale: .97}}
                                            onClick={confirm}
                                            href="https://caisaservices.com"
                                        >
                                            Visiter le site web
                                        </motion.a>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {err && (
                        <div className="error">
                            <h4>{err}</h4>
                        </div>
                    )}

                    {loading &&
                        <div className="loading-wrapper">
                            <div className="loading">
                                Patientez...
                            </div>
                        </div>
                    }
                </motion.div>

                <footer>
                    <motion.a className="caisa" href="https://caisaservices.com"
                        initial={{x: -200, opacity: 0}}
                        animate={{x: 0, opacity: 1}}
                        transition={{duration: 1}}
                    >

                    </motion.a>
                    <motion.a className="smart" href="https://projetsmart.com"
                        initial={{x: 200, opacity: 0}}
                        animate={{x: 0, opacity: 1}}
                        transition={{duration: 1}}
                    >

                    </motion.a>
                </footer>

            </div>
        </div>
    )
}

function AnimatedCheckIcon() {
    const circleControls = useAnimation();

  useEffect(() => {
    const animateCircle = async () => {
      await circleControls.start({
        opacity: 1, // Apparition progressive du cercle
        pathLength: .85, // Dessin progressif du cercle
        transition: { duration: 1.5 } // Durée de l'animation
      });
    };

    animateCircle();
  }, [circleControls]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      width="40"
      height="40"
    >
        <motion.circle
        cx="12"
        cy="12"
        r="11"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#EBA24D"
        opacity={0} // Initialisation de l'opacité à 0
        initial={{ pathLength: 0 }} // Initialisation de la longueur du tracé à 0
        animate={circleControls} // Animation contrôlée par circleControls
      />
        
        <motion.path
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="green"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{duration: .5, delay: .5}}
            d="M4.5 12.75l6 6 9-13.5"
        />
    </svg>
  );
}


export default InvCaisa;
