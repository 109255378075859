import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./styles/index.scss";
import InvCaisa from "./pages/invitations/Caisa";

function App() {
  return (
    <div className="App">
		<BrowserRouter>
			<Routes>
				<Route path="" element={<Home/>} />
				<Route path="/invitation/caisa/:pk" element={<InvCaisa/>}/>				
			</Routes>
		</BrowserRouter>
    </div>
  );
}

export default App;
