import { useCallback, useEffect, useState, useRef } from "react";



const useOnVisible = ({scrollParentSelector="#root", onVisible=()=>{}, onHide=()=>{}, weight=.5, once=false})=>{
    const ref = useRef(null);
    const [visible, setVisible] = useState(false);
    const already = useRef(false);

    const isVisible = useCallback (()=> {
        const ele = ref.current;
        if(!ele) return;

        const container = document.querySelector(scrollParentSelector);

        const { bottom, height, top } = ele.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
    
        if(top > containerRect.bottom || bottom < containerRect.top) return false;

        const vstart = Math.max(containerRect.top, top);
        const vend = Math.min(containerRect.bottom, bottom);
        const vheight = vend - vstart;

        return vheight >= height || containerRect.height * weight <= vheight;

    }, [scrollParentSelector, weight]);

    useEffect(()=>{
        const container = document.querySelector(scrollParentSelector);

        function onScroll(){
            setVisible(isVisible());
        }
        onScroll();
        container.addEventListener("scroll", onScroll);

        return ()=> container.removeEventListener("scrool", onScroll);

    }, [isVisible, scrollParentSelector])

    useEffect(()=>{

            if(visible){
               if(!once || (once && !already.current)) {
                onVisible(ref.current)
                already.current = true
            }
            }
            else{
                onHide(ref.current);
                
            }

    }, [visible, onVisible, onHide, ref, once])

    return [ref, visible]

}

export default useOnVisible;